import { Controller } from 'stimulus'

export default class extends Controller {
  addSubsidiary(event) {
    var $elem = $(this.element)
    var $hidden_subsidiaries_forms = $elem
      .parents('form')
      .find('.subsidiary-form-group:hidden')
    $hidden_subsidiaries_forms.first().show()

    // Disable button when max limit reached - 2 is correct value
    if ($hidden_subsidiaries_forms.length < 2) {
      $elem.find('a').text('Maximum reached')
      $elem.find('a').addClass('btn--disabled')
    }

    event.preventDefault()
  }

  removeSubsidiary(event) {
    var $elem = $(this.element)
    var $form_group = $elem.parents('.subsidiary-form-group')

    $form_group.hide()
    $form_group.find('input[type=text]').val('')
    $form_group.find('input[type=number]').val('')
    $form_group.find('input[type=radio]:checked').removeAttr('checked')

    var $hidden_subsidiaries_forms = $elem
      .parents('form')
      .find('.subsidiary-form-group:hidden')

    // Check for max entries and alter "Add Subsidiaries" button
    if ($hidden_subsidiaries_forms.length > 0) {
      $elem
        .parents('form')
        .find('a[data-identifier=addSubsidiariesButton]')
        .text('Add Subsidiary')
        .removeClass('btn--disabled')
    }

    event.preventDefault()
  }

  removeAllSubsidiary(event) {
    // When no subsidiaries checkbox is checked, reset values and hide Add Subsidiary button
    if (event.target.checked) {
      var $form_groups = $('.subsidiary-form-group')

      $form_groups.each(function () {
        var $form_group = $(this)

        $form_group.hide()
        $form_group.find('input[type=text]').val('')
        $form_group.find('input[type=number]').val('')
        $form_group.find('input[type=radio]:checked').removeAttr('checked')
      })

      $('#add-subsidiary-button').hide()
    } else {
      // When no subsidiaries checkbox is unchecked, show Add Subsidiary button
      $('#add-subsidiary-button').show()
    }
  }
}
