import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const trigger = 'js-form-show-more'
  const target = 'js-form-show-more-target'
  const transitionSpeed = '120ms'

  $(`[data-${trigger}]`).change(function () {
    const thisTarget = $(this).data(trigger)
    const $target = $(`[data-${target}=${thisTarget}]`)

    if (this.checked) {
      $target.slideDown(transitionSpeed)
      $(`[data-${target}]`).not($target).slideUp(transitionSpeed)
      return
    }
    $target.slideUp(transitionSpeed)
  })

  $(`[data-${target}]`).each(function () {
    $(this).css('display', 'none')
  })
})
