import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const version = getVersion()

  const all_recoverable = $(`#annual_report_ar_${version}_q4_1_a`)
  const mixed_recoverable = $(`#annual_report_ar_${version}_q4_2_a`)
  const not_recoverable = $(`#annual_report_ar_${version}_q4_5`)
  const investigated_opportunities = $(
    `input[name='annual_report[ar_${version}_q4_6_a]']`
  )
  const reusable_b2b_list = $(
    `input[type=checkbox][id^=annual_report_ar_${version}_q4_7_a]`
  )

  investigated_opportunities.filter(':checked').each(function () {
    toggleQ46b($(this), version)
  })

  investigated_opportunities.on('change', function () {
    toggleQ46b($(this), version)
  })

  reusable_b2b_list.each(function () {
    toggleQ47b($(this))
  })
  fixQ47bLabel(version)

  reusable_b2b_list.on('change', function () {
    toggleQ47b($(this))
    fixQ47bLabel(version)
  })

  if (not_recoverable.length) {
    not_recoverable.attr('readonly', 'readonly')
    var baseline_metric_quantity = not_recoverable.data(
      'baselineMetricQuantity'
    )

    // load the initial status
    if (
      all_recoverable.val().length > 0 ||
      mixed_recoverable.val().length > 0
    ) {
      calculateNonRecoverable(
        baseline_metric_quantity,
        all_recoverable.val(),
        mixed_recoverable.val(),
        not_recoverable
      )
    }

    // change the status on all_recoverable, mixed_recoverable keyup
    all_recoverable.on('keyup', function () {
      calculateNonRecoverable(
        baseline_metric_quantity,
        all_recoverable.val(),
        mixed_recoverable.val(),
        not_recoverable
      )
    })

    mixed_recoverable.on('keyup', function () {
      calculateNonRecoverable(
        baseline_metric_quantity,
        all_recoverable.val(),
        mixed_recoverable.val(),
        not_recoverable
      )
    })
  }
})

function getVersion() {
  // get the first input on the page
  const element = $('input[id^=annual_report_ar_]:first')
  if (element.attr('id') && element.attr('id').indexOf('v1') > 0) {
    return 'v1'
  }
  if (element.attr('id') && element.attr('id').indexOf('v3') > 0) {
    return 'v3'
  }
}

function toggleQ47b(elem) {
  if (elem.data('toggleTarget')) {
    var target = $(`label[for$=${elem.data('toggleTarget')}]`)
    if (elem.prop('checked')) {
      target
        .parents('.form-group')
        .removeClass('js-collapsed')
        .addClass('!flex')
    } else {
      target.parents('.form-group').find('input').prop('checked', false)
      target
        .parents('.form-group')
        .removeClass('!flex')
        .addClass('js-collapsed')
    }
  }
}

function calculateNonRecoverable(
  baseline_metric,
  all_recoverable,
  mixed_recoverable,
  total_target
) {
  total_target.val(baseline_metric - all_recoverable - mixed_recoverable)
}

function toggleQ46b(elem, version) {
  if (elem.val() && elem.val().toLowerCase() == 'yes') {
    $(`#annual_report_ar_${version}_q4_6_b`).removeAttr('disabled')
    $(`#annual_report_ar_${version}_q4_6_c`).removeAttr('disabled')
  } else {
    $(`#annual_report_ar_${version}_q4_6_b`).attr('disabled', 'disabled')
    $(`#annual_report_ar_${version}_q4_6_c`).attr('disabled', 'disabled')
    $(`#annual_report_ar_${version}_q4_6_b`).val('')
    $(`#annual_report_ar_${version}_q4_6_c`).val('')
  }
}

function fixQ47bLabel(version) {
  const q4_7_b_label = $(
    `label[for='annual_report_ar_${version}_q4_7_b_group']`
  )

  // count all q4_7_a selected items except none-of-above one
  const countOptions = $(
    `input[type=checkbox][id^=annual_report_ar_${version}_q4_7_a]:not([id$="_none"]):checked`
  ).length

  if (countOptions > 0) {
    q4_7_b_label
      .parents('.form-group')
      .removeClass('js-collapsed')
      .addClass('!flex')
  } else {
    q4_7_b_label
      .parents('.form-group')
      .removeClass('!flex')
      .addClass('js-collapsed')
  }
}
