// Handler newsletter signup functionality
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'inputgroup',
    'email',
    'firstname',
    'form',
    'lastname',
    'message',
    'signupNames',
  ]

  updateExisting() {
    // For existing records we do not want to update name fields so make sure
    // that they are blank before we submit the form.
    this.firstnameTarget.value = ''
    this.lastnameTarget.value = ''
    this.formTarget.submit()
  }

  get namesPresent() {
    return this.firstnameTarget.value && this.lastnameTarget.value
  }

  showNameFields() {
    this.inputgroupTarget.classList.remove('input-group')
    this.messageTarget.style.display = 'block'
    this.firstnameTarget.required = true
    this.firstnameTarget.style.display = 'block'
    this.lastnameTarget.required = true
    this.lastnameTarget.style.display = 'block'
  }

  async ajaxBeforeSend(event) {
    event.preventDefault()

    const emailExists = await this.emailExists(this.emailTarget.value)
    if (emailExists) {
      // Contact record for email exists - submit the form to update it.
      this.updateExisting()
    } else {
      if (this.namesPresent) {
        // Contact record for email does not exist but we have names.
        // Submit form to create new record.
        this.formTarget.submit()
      } else {
        // Contact record for email does not exist.
        // Display name fields so that user can input names.
        this.showNameFields()
      }
    }
  }

  async emailExists(email) {
    const path = '/newsletters/email_exists'
    const url = new URL(path, window.location.origin)
    url.search = new URLSearchParams({ email: email }).toString()

    const response = await fetch(url)
    const json = await response.json()
    return json.exists
  }
}
