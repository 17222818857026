// Handler newsletter signup functionality
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'name_wrapper',
    'abn',
    'abn_wrapper',
    'company_type',
    'company_type_wrapper',
    'member_group',
    'anzsic',
    'anzsic_wrapper',
  ]

  updateAbnOrName() {
    if (this.company_typeTarget.value == 'International_Affiliate') {
      this.showName(true)
      this.showABN(false)
      this.showAnzsicCode(false)
    } else {
      this.showName(false)
      this.showABN(true)
      this.showAnzsicCode(true)
    }
  }

  showName(show) {
    this.nameTarget.disabled = !show
    this.name_wrapperTarget.style.display = show ? 'block' : 'none'
  }

  showABN(show) {
    this.abnTarget.disabled = !show
    this.abn_wrapperTarget.style.display = show ? 'block' : 'none'
  }

  showAnzsicCode(show) {
    this.anzsicTarget.disabled = !show
    this.anzsic_wrapperTarget.style.display = show ? 'block' : 'none'
  }

  showCompanyType(show) {
    this.company_type_wrapperTarget.style.display = show ? 'block' : 'none'
  }

  setVisibleCompanyTypes(visibleTypes) {
    if (!Object.values(visibleTypes).includes(this.company_typeTarget.value)) {
      this.company_typeTarget.value = ''
    }

    ;[...this.company_typeTarget.options].forEach((option) => {
      option.style.display = Object.values(visibleTypes).includes(option.value)
        ? 'block'
        : 'none'
    })
  }

  setMemberTypes() {
    const companyTypeMappings = JSON.parse(this.company_typeTarget.dataset.info)

    if (
      ['Government', 'Covenant Supporter'].includes(
        this.member_groupTarget.value
      )
    ) {
      this.showCompanyType(true)
      this.setVisibleCompanyTypes(
        companyTypeMappings[this.member_groupTarget.value]
      )
    } else {
      this.showCompanyType(false)
      this.company_typeTarget.value =
        companyTypeMappings[this.member_groupTarget.value]
    }

    this.updateAbnOrName()
  }

  connect() {
    this.setMemberTypes()
  }
}
