import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const version = getVersion()
  const element = $(`input[name='annual_report[ar_${version}_q1_1_a]']`)

  // Load the initial status
  element.filter(':checked').each(function () {
    toggleQ11($(this), version)
  })

  // change the status on change
  element.on('change', function () {
    toggleQ11($(this), version)
  })
})

function getVersion() {
  // get the first input on the page
  const element = $('input[id^=annual_report_ar_]:first')
  if (element.attr('id') && element.attr('id').indexOf('v1') > 0) {
    return 'v1'
  }
  if (element.attr('id') && element.attr('id').indexOf('v3') > 0) {
    return 'v3'
  }
}

function toggleQ11(elem, version) {
  if (elem.val() && elem.val().toLowerCase() == 'yes') {
    $(`input[name='annual_report[ar_${version}_q1_1_b]']`)
      .parents('.custom-control--reporting-tool')
      .removeClass('inaccessible')
    $(`input[name='annual_report[ar_${version}_q1_1_c]']`)
      .parents('.custom-control--reporting-tool')
      .removeClass('inaccessible')
  } else {
    $(`input[name='annual_report[ar_${version}_q1_1_b]']`).prop(
      'checked',
      false
    )
    $(`input[name='annual_report[ar_${version}_q1_1_b]']`)
      .parents('.custom-control--reporting-tool')
      .addClass('inaccessible')

    $(`input[name='annual_report[ar_${version}_q1_1_c]']`).prop(
      'checked',
      false
    )
    $(`input[name='annual_report[ar_${version}_q1_1_c]']`)
      .parents('.custom-control--reporting-tool')
      .addClass('inaccessible')
  }
}
