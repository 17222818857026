import { Controller } from 'stimulus'
import queryString from 'query-string'

let debounce = require('lodash/debounce')

export default class extends Controller {
  static targets = ['query', 'results']

  initialize() {
    this.refreshResults = debounce(this.refreshResults, 400)
  }

  refreshResults() {
    if (this.queryTarget.value === '' || this.queryTarget.value == undefined) {
      this.resultsTarget.innerHTML = ''
    } else {
      let params = { 'site_search_form[query]': this.queryTarget.value }

      fetch(`/search/inline?${queryString.stringify(params)}`, {
        method: 'GET',
      })
        .then((response) => response.text())
        .then((html) => {
          this.resultsTarget.innerHTML = html
        })
    }
  }

  viewFullSearchResults(event) {
    event.preventDefault()
    let params = { 'site_search_form[query]': this.queryTarget.value }
    window.location.href = `/search?${queryString.stringify(params)}`
  }
}
