// Enable infinite scrolling
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'loading', 'memberList']

  constructor(context) {
    super(context)
    // If we don't bind `onIntersection` to `this`, the functions's `this`
    // is set to `IntersectionObserver` when it is called.
    this.onIntersection = this.onIntersection.bind(this)
  }

  connect() {
    // Observe whether the "Load more" button is visible within the top-level
    // document's viewport
    this.observer = new IntersectionObserver(this.onIntersection, {
      root: null,
    })
    this.observer.observe(this.buttonTarget)
  }

  disconnect() {
    this.observer.disconnect()
  }

  get nextPageUrlIsBlank() {
    // 'null' -> set after fetching the last
    // '' -> set if the first page is the last page
    return this.nextPageUrl === 'null' || this.nextPageUrl === ''
  }

  get nextPageUrl() {
    return this.data.get('nextPageUrl')
  }

  set nextPageUrl(value) {
    return this.data.set('nextPageUrl', value)
  }

  get loading() {
    // We test against the the string 'true' because Stimulus converts ALL
    // values to strings when storing them.
    return this.data.get('loading') === 'true'
  }

  set loading(value) {
    this.data.set('loading', value)
    this.loadingTarget.style.display = value ? 'block' : 'none'
    this.buttonTarget.disabled = value
  }

  onIntersection(entries) {
    const [entry] = entries
    if (entry.isIntersecting) this.load()
  }

  hideLoadButton() {
    this.buttonTarget.style.display = this.nextPageUrlIsBlank
      ? 'none'
      : 'inline-block'
  }

  updatePageDetails(pageDetails) {
    this.nextPageUrl = pageDetails.next_page_url
    this.hideLoadButton()
    this.memberListTarget.insertAdjacentHTML(
      'beforeend',
      pageDetails.html || ''
    )
  }

  async load() {
    if (this.loading) return
    if (this.nextPageUrlIsBlank) return

    this.loading = true
    const pageDetails = await this.nextPageDetails().catch((e) =>
      console.error('Unable to load next page', e.message)
    )
    this.updatePageDetails(pageDetails)
    this.loading = false
  }

  get headers() {
    return { 'X-Requested-With': 'XMLHttpRequest' }
  }

  async nextPageDetails() {
    const response = await fetch(this.nextPageUrl, { headers: this.headers })
    if (response.status < 200 || response.status >= 300) {
      throw new Error(`Error - HTTP status ${response.status} from ${url}`)
    }
    return response.json()
  }
}
