import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'quantity', 'total']

  updateTotal() {
    let quantity = this.quantityTarget.value
    let price = this.priceTarget.value

    this.totalTarget.value = parseFloat(quantity * price).toFixed(2)
  }

  confirm(event) {
    if (
      !window.confirm(
        "You are about to purchase additional PREP Licenses.\nOnce you confirm purchase, an invoice will be issued and sent\nto the organisation's nominated accounts contact."
      )
    ) {
      event.preventDefault()
    }
  }
}
