import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  // TODO: Move to "onClick" event
  const toggleButton = $('#customTimeframeToggle')

  toggleButton.on('click', function () {
    $(this).parents('.form-group').find('.custom-control').show()
  })
})
