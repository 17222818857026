import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  $('[data-js-dynamic-video-player]').on('click keypress', function () {
    const modal_name = $(this).data('jsModalTrigger')
    const modal_elem = $(`[data-js-modal=${modal_name}]`)

    modal_elem.find('[data-js-loading]').hide()
    modal_elem.find('[data-js-video-player]').show()

    const target_elem = $(this)

    const video_description = target_elem.data('jsDynamicVideoDescription')
    const video_id = target_elem.data('jsDynamicVideoId')
    const video_title = target_elem.data('jsDynamicVideoTitle')

    const iframe = modal_elem.find('iframe')
    iframe.attr('title', video_title)

    const src = iframe.attr('src')

    const regex = /\/\d+/ // selects /video_id_number from url, excluding params)

    iframe.attr('src', src.replace(regex, `/${video_id}`))
    modal_elem.find('[data-js-video-title]').text(video_title)
    modal_elem.find('[data-js-video-description]').text(video_description)
  })
})
