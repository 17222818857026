import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const no_additional_info = $('#annual_report_no_additional_information')
  if (no_additional_info.length) {
    // load the initial status
    toggle_additional_info(no_additional_info)

    // change the status on click
    no_additional_info.on('click', function () {
      toggle_additional_info($(this))
    })
  }

  const no_additional_info_arl = $(
    '#arl_annual_report_no_additional_information'
  )
  if (no_additional_info_arl.length) {
    // load the initial status
    toggle_additional_info(no_additional_info_arl)

    // change the status on click
    no_additional_info_arl.on('click', function () {
      toggle_additional_info($(this))
    })
  }
})

function toggle_additional_info(element) {
  if (element.prop('checked')) {
    // clear and disable the fields
    $('.textarea').val('')
    $('.textarea').parents('.form-group').addClass('inaccessible')
  } else {
    // enable the fields
    $('.textarea').parents('.form-group').removeClass('inaccessible')
  }
}
