import $ from 'jquery'

$(document).on('turbolinks:load', function () {
  // Hide the section using .js-collapsed
  // Receive two classes to toggle
  window.togglePage = function (firstClass, secondClass) {
    $(`.${firstClass}`).toggleClass('js-collapsed')
    $(`.${secondClass}`).toggleClass('js-collapsed')
  }
})
