import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  $('#primary-prep-select').on('change', function () {
    let account_contact_id = $(this).val()

    clearPrimaryPrepUser()

    $(
      '#_account_members_' +
        account_contact_id +
        '_account_contact_relationship_primary_prep_user'
    ).val(true)
  })

  $('.access-prep').on('click', function () {
    let access_prep_item = $(this)
    let selected_prep_member_id = access_prep_item.attr('data-id')
    let available_prep_license = parseInt($('.available-prep').text())
    let allocated_prep_license = parseInt($('.allocated-prep').text())
    let primary_prep_user_id = $('#primary-prep-select').val()
    let on_prep_user_list = false

    if (access_prep_item.is(':checked')) {
      if (available_prep_license > 0) {
        available_prep_license -= 1
        allocated_prep_license += 1

        // check if the member is on the primary prep user list
        $('#primary-prep-select option').each(function () {
          if ($(this).val() == selected_prep_member_id) {
            on_prep_user_list = true
            return false
          }
        })

        updateFilterKeys(selected_prep_member_id, 'access_to_prep', 'add')

        // add the member to the prep user list
        if (on_prep_user_list == false) {
          let prep_user_name = $(
            '#_account_members_' +
              selected_prep_member_id +
              '_contact_first_name'
          ).val()
          prep_user_name +=
            ' ' +
            $(
              '#_account_members_' +
                selected_prep_member_id +
                '_contact_last_name'
            ).val()

          $('#primary-prep-select').append(
            `<option value="${selected_prep_member_id}">${prep_user_name}</option>`
          )
        }
        $('.primary-prep-user-select-container').show()
      }
    } else {
      available_prep_license += 1
      allocated_prep_license -= 1

      updateFilterKeys(selected_prep_member_id, 'access_to_prep', 'remove')

      // check if the unchecked member is the primary prep user
      if (selected_prep_member_id == primary_prep_user_id) {
        clearPrimaryPrepUser()
        $('#primary-prep-select option:selected').removeAttr('selected')
      }

      // remove the member from the prep user list
      $('#primary-prep-select option').each(function () {
        if ($(this).val() == selected_prep_member_id) {
          $(this).remove()
          return false
        }
      })

      if (allocated_prep_license == 0) {
        $('.primary-prep-user-select-container').hide()
      }
    }

    $('.available-prep').text(available_prep_license)
    $('.allocated-prep').text(allocated_prep_license)
  })

  $('.manage-members').on('submit', function (event) {
    if ($('#primary-prep-select').length > 0) {
      let options = $('#primary-prep-select option').map(function () {
        return this.value
      })

      if (options.length > 1 && $('#primary-prep-select').val() == '') {
        event.preventDefault()
        $('.primary-prep-msg').text('Please select a primary prep user.')
        $('html, body').animate({ scrollTop: 0 }, 'slow')
      }
    }
  })

  $('.access-arl').on('click', function () {
    let access_to_arl = $(this)
    let selected_member_id = access_to_arl.attr('data-id')
    if (access_to_arl.is(':checked')) {
      updateFilterKeys(selected_member_id, 'access_to_arl', 'add')
    } else {
      updateFilterKeys(selected_member_id, 'access_to_arl', 'remove')
    }
  })

  $('.access-reporting-tool').on('click', function () {
    let access_reporting_tool = $(this)
    let selected_member_id = access_reporting_tool.attr('data-id')
    if (access_reporting_tool.is(':checked')) {
      updateFilterKeys(selected_member_id, 'access_to_reporting_tool', 'add')
    } else {
      updateFilterKeys(selected_member_id, 'access_to_reporting_tool', 'remove')
    }
  })
})

function updateFilterKeys(id, key, mode) {
  let filter_keys = $('#account-member-wrapper-' + id)
    .attr('data-filter-keys')
    .split(',')
  if (mode == 'add') {
    filter_keys.push(key)
  } else {
    filter_keys.splice(filter_keys.indexOf(key), 1)
  }
  $('#account-member-wrapper-' + id).attr('data-filter-keys', filter_keys)
}

function clearPrimaryPrepUser() {
  $('.primary-prep-msg').text('')
  $('.prep-user').each(function () {
    $(this).val(false)
  })
}
