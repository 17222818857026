// Handler newsletter signup functionality
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'primary_supply_chain_position',
    'other_supply_chain_positions',
    'primary_industry_sector',
    'primary_industry_sub_categories',
    'secondary_industry_sector',
    'secondary_industry_sub_categories',
  ]

  updateOtherSupplyChainPositions() {
    let supplyChainPositions = [
      'Retailer (point of sale)',
      'Manufacturer',
      'Importer/Supplier',
    ]

    while (this.other_supply_chain_positionsTarget.options.length > 0) {
      this.other_supply_chain_positionsTarget.remove(
        this.other_supply_chain_positionsTarget.options.length - 1
      )
    }

    const otherPositions = supplyChainPositions.filter((x) => {
      return x != this.primary_supply_chain_positionTarget.value
    })

    otherPositions.forEach((value) => {
      this.other_supply_chain_positionsTarget.innerHTML += `<option>${value}</option>`
    })
  }

  updatePrimaryIndustrySectors() {
    const industryTypes = JSON.parse(
      this.primary_industry_sectorTarget.dataset.info
    )

    const options = this.primary_industry_sub_categoriesTarget.options

    let i = 0
    for (; i < options.length; i++) {
      if (
        this.primary_industry_sectorTarget.value &&
        industryTypes[this.primary_industry_sectorTarget.value].includes(
          options[i].value
        )
      ) {
        options[i].style.display = 'block'
      } else {
        options[i].style.display = 'none'
        options[i].selected = false
      }
    }
  }

  updateSecondaryIndustrySectors() {
    const industryTypes = JSON.parse(
      this.secondary_industry_sectorTarget.dataset.info
    )

    const options = this.secondary_industry_sub_categoriesTarget.options

    let i = 0
    for (; i < options.length; i++) {
      if (
        this.secondary_industry_sectorTarget.value &&
        industryTypes[this.secondary_industry_sectorTarget.value].includes(
          options[i].value
        )
      ) {
        options[i].style.display = 'block'
      } else {
        options[i].style.display = 'none'
        options[i].selected = false
      }
    }
  }

  connect() {
    this.updatePrimaryIndustrySectors()
    this.updateSecondaryIndustrySectors()
  }
}
