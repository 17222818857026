import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const elem = $('#annual_report_ar_v1_q2_6_a')
  const targetElem = $('#annual_report_ar_v1_q2_6_c')

  toggleQ26c(elem, targetElem)

  elem.on('change', function () {
    toggleQ26c($(this), targetElem)
  })
})

// toggle functionality for V1 Q2 6 c
function toggleQ26c(elem, targetElem) {
  if (elem.val() <= 0) {
    targetElem.val(null)
    targetElem.parents('.form-group').addClass('inaccessible')
    targetElem.attr('disabled', 'disabled')
  } else {
    targetElem.parents('.form-group').removeClass('inaccessible')
    targetElem.removeAttr('disabled')
  }
}
