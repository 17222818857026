// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery')
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('cocoon')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js'

import 'controllers'
import 'dropdown'
import 'slider'
import 'collapse'
import 'modal'
import 'form_show_more'
import 'video_modal'
import 'toggle_page'
import 'reporting_centre_timeframes'
import 'reporting_centre_packaging_metrics'
import 'reporting_centre_additional_information'
import 'reporting_centre_problematic_materials'
import 'reporting_centre_design_and_procurement'
import 'reporting_centre_recoverability'
import 'reporting_centre_governance_strategy'
import 'reporting_centre_on_site_waste'
import 'reporting_centre_setup'
import 'primary_prep_user'
import 'annual_reports'
import 'email_preferences'

// Import CSS
import('../styles/application.css')
