// Submit "GET" forms via Turbolinks
import { Controller } from 'stimulus'
import queryString from 'query-string'
let debounce = require('lodash/debounce')

export default class extends Controller {
  static targets = [
    'member_types',
    'industry_sectors',
    'locations',
    'keywords',
    'results',
  ]

  initialize() {
    this.refreshResults = debounce(this.refreshResults, 300)
  }

  refreshResults() {
    let params = {
      keywords: this.keywordsTarget.value,
      ...(this.member_typesTarget.value != '' && {
        'member_types[]': [...this.member_typesTarget.selectedOptions].map(
          (option) => option.value
        ),
      }),
      ...(this.industry_sectorsTarget.value != '' && {
        'industry_sectors[]': [
          ...this.industry_sectorsTarget.selectedOptions,
        ].map((option) => option.value),
      }),
      ...(this.locationsTarget.value != '' && {
        'locations[]': [...this.locationsTarget.selectedOptions].map(
          (option) => option.value
        ),
      }),
    }

    fetch(`/apco-members/search/?${queryString.stringify(params)}`, {
      method: 'GET',
    })
      .then((response) => response.text())
      .then((html) => {
        this.resultsTarget.innerHTML = html
      })
  }

  submitForm(event) {
    const {
      detail: [_, { url }],
    } = event

    Turbolinks.visit(url)
    event.preventDefault()
  }
}
