import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filter', 'filterable']

  filterMember(event) {
    event.preventDefault()

    let filter_options = []

    this.filterTargets.forEach((filter_target) => {
      if (filter_target.checked) {
        filter_options.push(filter_target.value)
      }
    })

    this.filterableTargets.forEach((el, i) => {
      let filterableKeys = el.getAttribute('data-filter-keys')

      el.classList.toggle('!none', !filterableKeys.includes(filter_options))
    })
  }

  addRole(event) {
    var $form = $(event.currentTarget.closest('.mc-card-form'))
    var $role_fields_template = $form.find(
      '[data-account-contact-relationship-role-fields-wrapper]:last'
    )

    // Copy and clone the role fields template to make a new one.
    var $new_role_fields = $role_fields_template.clone()
    $role_fields_template.after($new_role_fields)

    // Default the selected <option> (for some reason .val() wouldn't work).
    $new_role_fields.find('option').removeAttr('selected')
    $new_role_fields
      .find('option[value="Other Contact"]')
      .prop('selected', 'selected')

    event.preventDefault()
  }

  deleteRole(event) {
    var $form = $(event.currentTarget.closest('.mc-card-form'))
    var $delete_role_button = $(event.currentTarget)
    var $role_fields_wrappers = $form.find(
      '[data-account-contact-relationship-role-fields-wrapper]'
    )
    var $role_fields_wrapper_to_delete = $delete_role_button.parents(
      '[data-account-contact-relationship-role-fields-wrapper]'
    )

    // We don't ever want to have no roles, so if we're about to delete the only/last one, add a new one first.
    if ($role_fields_wrappers.length === 1) {
      this.addRole(event)
    }

    $role_fields_wrapper_to_delete.remove()

    event.preventDefault()
  }

  addMember(event) {
    var $add_contact_button = $(event.currentTarget)
    var $contact_form = $('.mc-card-form:last')

    $add_contact_button
      .prop('disabled', 'disabled')
      .text('Add a Member (save to add another)')
    $contact_form.hide().removeClass('!none').slideDown()

    event.preventDefault()
  }

  deleteMember(event) {
    var $form = $(event.currentTarget.closest('.mc-card-form'))
    var $contact_delete_field = $form.find('input[data-contact-delete-field]')

    // Update the hidden input and hide the element.
    $contact_delete_field.val('true')
    $form.slideUp()

    event.preventDefault()
  }
}
