import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  // getting all :ar_v3_q7_2_group items
  const checkboxes = $(
    'input[type=checkbox][id^=annual_report_ar_v3_q7_2], input[type=checkbox][id^=annual_report_ar_v1_q7_2]'
  )

  const version = getVersionFromCheckboxes(checkboxes)

  // load the initial status
  checkboxes.each(function () {
    showOrHideCorrelated($(this), version)
  })
  fixLabels(version)

  // change the status on click
  checkboxes.on('change', function () {
    showOrHideCorrelated($(this), version)
    fixLabels(version)
  })

  // getting all :ar_v3_q7_3_group and :ar_v3_q7_4_group items
  const q73 = `input[type=checkbox][id^=annual_report_ar_v${version}_q7_3]`
  const q74 = `input[type=checkbox][id^=annual_report_ar_v${version}_q7_4]`
  const q73And74Checkboxes = $(`${q73}, ${q74}`)

  // Multiple Exclusive answers between 7_3 and 7_4 answers
  // When the user checks 7_3, the correlated 7_4 needs to be inaccessible
  // and vice and versa
  q73And74Checkboxes.each(function () {
    activateOrInactivateTarget($(this))
  })
  q73And74Checkboxes.on('change', function () {
    activateOrInactivateTarget($(this))
  })
})

function getVersionFromCheckboxes(checkboxes) {
  if (checkboxes.length > 0) {
    if (checkboxes.first().attr('id').indexOf('v1') > 0) {
      var version = 1
    }

    if (checkboxes.first().attr('id').indexOf('v3') > 0) {
      var version = 3
    }

    return version
  }
}

// 7_2 items show/hide 7_3 and 7_4 items
function showOrHideCorrelated(element, version) {
  const id_7_3 = element.attr('id').replace('q7_2', 'q7_3')
  const id_7_4 = element.attr('id').replace('q7_2', 'q7_4')

  // not-applicable/none-of-the-above item doesn't follow the rule
  if (
    element.attr('id') != `annual_report_ar_v${version}_q7_2_j` &&
    element.attr('id') != `annual_report_ar_v${version}_q7_2_none`
  ) {
    toggleRelevantItems(element, $(`#${id_7_3}`), $(`#${id_7_4}`))
  }
}

function toggleRelevantItems(element, element1, element2) {
  if (element.prop('checked')) {
    showFormGroup(element1)
    showFormGroup(element2)
  } else {
    element1.prop('checked', false)
    element2.prop('checked', false)

    hideFormGroup(element1)
    hideFormGroup(element2)
  }
}

// For 7_3 and 7_4
// - labels show/hide depending on items presented
// - not-applicable/none-of-the-above items follow the same behaviour
function fixLabels(version) {
  const label1 = $(`label[for='annual_report_ar_v${version}_q7_3_group']`)
  const label2 = $(`label[for='annual_report_ar_v${version}_q7_4_group']`)
  const notApplicable1 = $(`#annual_report_ar_v${version}_q7_3_j`)
  const notApplicable2 = $(`#annual_report_ar_v${version}_q7_4_j`)
  const noneOfTheAbove1 = $(`#annual_report_ar_v${version}_q7_3_none`)
  const noneOfTheAbove2 = $(`#annual_report_ar_v${version}_q7_4_none`)

  // count all selected items except not-applicable one
  const countOptions = $(
    `input[type=checkbox][id^=annual_report_ar_v${version}_q7_2]:not([id$="_j"]):not([id$="_none"]):checked`
  ).length

  if (countOptions > 0) {
    showFormGroup(label1)
    showFormGroup(label2)
    label1.parents('.card__action_plan-field').removeClass('js-collapsed')
    label2.parents('.card__action_plan-field').removeClass('js-collapsed')
    showFormGroup(notApplicable1)
    showFormGroup(notApplicable2)
    showFormGroup(noneOfTheAbove1)
    showFormGroup(noneOfTheAbove2)
  } else {
    hideFormGroup(label1)
    hideFormGroup(label2)
    label1.parents('.card__action_plan-field').addClass('js-collapsed')
    label2.parents('.card__action_plan-field').addClass('js-collapsed')
    notApplicable1.prop('checked', false)
    notApplicable2.prop('checked', false)
    noneOfTheAbove1.prop('checked', false)
    noneOfTheAbove2.prop('checked', false)
    hideFormGroup(notApplicable1)
    hideFormGroup(notApplicable2)
    hideFormGroup(noneOfTheAbove1)
    hideFormGroup(noneOfTheAbove2)
  }
}

function activateOrInactivateTarget(element) {
  if (element.data('toggleTarget')) {
    var target = $(`label[for$=${element.data('toggleTarget')}]`)
    if (element.prop('checked')) {
      target.parents('.form-group').find('input').prop('checked', false)
      target.parents('.form-group').addClass('inaccessible')
    } else {
      target.parents('.form-group').removeClass('inaccessible')
    }
  }
}

function hideFormGroup(element) {
  element.parents('.form-group').removeClass('!flex').addClass('js-collapsed')
}

function showFormGroup(element) {
  element.parents('.form-group').removeClass('js-collapsed').addClass('!flex')
}
