import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const version = getVersion()
  const checkboxes = $('.custom-control__checkbox')

  // load the initial status
  checkboxes.each(function () {
    toggleMaterial($(this), version)
  })

  // change the status on click
  checkboxes.on('click', function () {
    toggleMaterial($(this), version)
  })

  $('.packaging-metrics-fields input[id$=_iii]').prop('readonly', true)

  // triggered when changing totalTonnesUsed
  $('.packaging-metrics-fields input[id$=_i]').on('change', function () {
    const identifier = $(this).attr('id').replace('_i', '')
    calculateTotalSingleUse(identifier)
  })

  // triggered when changing tonnesReusable
  $('.packaging-metrics-fields input[id$=_ii]').on('change', function () {
    const identifier = $(this).attr('id').replace('_ii', '')
    calculateTotalSingleUse(identifier)
  })

  if (version == 'v3') {
    $('.packaging-metrics-fields input[id$=_viii]').prop('readonly', true)

    // triggered when changing sourcedLocally
    $('.packaging-metrics-fields input[id$=_vii]').on('change', function () {
      const identifier = $(this).attr('id').replace('_vii', '')
      calculateImported(identifier)
    })
  }
})

function getVersion() {
  // get the first input on the page
  const element = $('input[id^=annual_report_ar_]:first')
  if (element.attr('id') && element.attr('id').indexOf('v1') > 0) {
    return 'v1'
  }
  if (element.attr('id') && element.attr('id').indexOf('v3') > 0) {
    return 'v3'
  }
}

function toggleMaterial(element, version) {
  const value_id = element
    .attr('id')
    .replace(`annual_report_ar_${version}_q8_1_selected_materials_`, '')

  if (!element.prop('checked')) {
    // clear and hide the fields
    $(`.${value_id} input`).each(function () {
      $(this).val('')
    })
    $(`.${value_id}`).hide()
  } else {
    $(`.${value_id}`).show()
  }
}

function calculateTotalSingleUse(identifier) {
  // totalTonnesUsed is a field with the sufix "_i"
  const totalTonnesUsed = $(`#${identifier}_i`)

  // tonnesReusable is a field with the sufix "_ii"
  const tonnesReusable = $(`#${identifier}_ii`)

  // totalSingleUse is a field with the sufix "_iii"
  const totalSingleUse = $(`#${identifier}_iii`)

  if (tonnesReusable.val() && totalTonnesUsed.val()) {
    totalSingleUse.val(totalTonnesUsed.val() - tonnesReusable.val())
  } else {
    totalSingleUse.val('')
  }
}

function calculateImported(identifier) {
  // sourcedLocally is a field with the sufix "_vii"
  const sourcedLocally = $(`#${identifier}_vii`)

  // imported is a field with the sufix "_viii"
  const imported = $(`#${identifier}_viii`)

  if (sourcedLocally.val()) {
    imported.val(100 - sourcedLocally.val())
  } else {
    totalSingleUse.val('')
  }
}
