import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const version = getVersion()

  const element = $(`#annual_report_ar_${version}_q6_2_a`)

  // Load the initial status
  toggleCorrelatedItems(element, version)

  // change the status on change
  element.on('change', function () {
    toggleCorrelatedItems($(this), version)
  })
})

function getVersion() {
  // get the first input on the page
  const element = $('input[id^=annual_report_ar_]:first')
  if (element.attr('id') && element.attr('id').indexOf('v1') > 0) {
    return 'v1'
  }
  if (element.attr('id') && element.attr('id').indexOf('v3') > 0) {
    return 'v3'
  }
}

function toggleCorrelatedItems(element, version) {
  if (element.val() > 0) {
    $(`#annual_report_ar_${version}_q6_2_b`)
      .parents('.form-group')
      .removeClass('inaccessible')

    $(`label[for='annual_report_ar_${version}_q6_2_c_group']`)
      .parents('.form-group')
      .removeClass('inaccessible')

    $(`input[type=checkbox][id^=annual_report_ar_${version}_q6_2_c]`).each(
      function () {
        $(this).parents('.form-group').removeClass('inaccessible')
      }
    )

    $(`#annual_report_ar_${version}_q6_2_c_v_notes`)
      .parents('.form-group')
      .removeClass('inaccessible')

    $(`#annual_report_ar_${version}_q6_2_c_notes`)
      .parents('.form-group')
      .removeClass('inaccessible')
  } else {
    $(`#annual_report_ar_${version}_q6_2_b`).val('')
    $(`#annual_report_ar_${version}_q6_2_b`)
      .parents('.form-group')
      .addClass('inaccessible')

    $(`label[for='annual_report_ar_${version}_q6_2_c_group']`)
      .parents('.form-group')
      .addClass('inaccessible')

    $(`input[type=checkbox][id^=annual_report_ar_${version}_q6_2_c]`).each(
      function () {
        $(this).prop('checked', false)
        $(this).parents('.form-group').addClass('inaccessible')
      }
    )

    $(`#annual_report_ar_${version}_q6_2_c_v_notes`).val('')
    $(`#annual_report_ar_${version}_q6_2_c_v_notes`)
      .parents('.form-group')
      .addClass('inaccessible')

    $(`#annual_report_ar_${version}_q6_2_c_notes`).val('')
    $(`#annual_report_ar_${version}_q6_2_c_notes`)
      .parents('.form-group')
      .addClass('inaccessible')
  }
}
