import $ from 'jquery'
import { tns } from 'tiny-slider/src/tiny-slider'

$(document).on('turbolinks:load', function () {
  if ($('[data-js-member-scroller]').length) {
    tns({
      container: '[data-js-member-scroller]',
      controlsContainer: '[data-js-member-scroller-controls]',
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      nav: false,
      items: 6,
      slideBy: 'page',
      autoplay: true,
      speed: 1500,
      autoplayTimeout: 8000,
      mouseDrag: true,
      lazyload: true,
      freezable: false,
      responsive: {
        768: {
          items: 8,
        },
        1000: {
          items: 12,
        },
      },
      onInit(info) {
        info.controlsContainer.removeAttribute('tabindex')
        info.nextButton.setAttribute('tabindex', 0)
        info.prevButton.setAttribute('tabindex', 0)
      },
    })
  }

  if ($('[data-js-hero-slider]').length) {
    tns({
      container: '[data-js-hero-slider]',
      controlsContainer: '[data-js-hero-slider-controls]',
      autoplayButtonOutput: false,
      autoplayHoverPause: true,
      nav: false,
      items: 1,
      slideBy: 'page',
      autoplay: true,
      speed: 1400,
      autoplayTimeout: 8000,
      mouseDrag: true,
      lazyload: true,
      freezable: false,
      center: true,
      onInit(info) {
        info.controlsContainer.removeAttribute('tabindex')
        info.nextButton.setAttribute('tabindex', 0)
        info.prevButton.setAttribute('tabindex', 0)
      },
    })
  }
})
