import $ from 'jquery'

$(document).on('turbolinks:load', function (e) {
  const none = $('#arl_annual_report_arl_q0_none')

  // load the initial status
  activateOrInactivate(none)

  // change the status on click
  none.on('change', function () {
    activateOrInactivate(none)
  })
})

function activateOrInactivate(none) {
  const aus = $('#arl_annual_report_arl_q0_aus')
  const nz = $('#arl_annual_report_arl_q0_nz')

  if (none.prop('checked')) {
    aus.prop('checked', false)
    nz.prop('checked', false)
    aus.parents('.custom-control').addClass('inaccessible')
    nz.parents('.custom-control').addClass('inaccessible')
  } else {
    aus.parents('.custom-control').removeClass('inaccessible')
    nz.parents('.custom-control').removeClass('inaccessible')
  }
}
